.elm-datepicker--container {
  position: relative;
}

.elm-datepicker--input:focus {
  outline: 0;
}

.elm-datepicker--picker {
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ccc;
  position: absolute;
}

.elm-datepicker--picker-header, .elm-datepicker--weekdays {
  background: #f2f2f2;
}

.elm-datepicker--picker-header {
  align-items: center;
  display: flex;
}

.elm-datepicker--prev-container, .elm-datepicker--next-container {
  cursor: pointer;
  flex: 0 auto;
}

.elm-datepicker--month-container {
  flex-direction: column;
  flex: auto;
  padding: .5em;
  display: flex;
}

.elm-datepicker--month, .elm-datepicker--year {
  cursor: default;
  text-align: center;
  flex: auto;
}

.elm-datepicker--year {
  font-size: .6em;
  font-weight: 700;
}

.elm-datepicker--prev, .elm-datepicker--next {
  background-color: inherit;
  border: 6px solid #0000;
  width: 0;
  height: 0;
  padding: 0 .2em;
  display: block;
}

.elm-datepicker--prev {
  border-right-color: #aaa;
}

.elm-datepicker--prev:hover {
  border-right-color: #bbb;
}

.elm-datepicker--next {
  border-left-color: #aaa;
}

.elm-datepicker--next:hover {
  border-left-color: #bbb;
}

.elm-datepicker--table {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: .8em;
}

.elm-datepicker--table td {
  text-align: center;
  width: 2em;
  height: 2em;
}

.elm-datepicker--row {
  border-top: 1px solid #f2f2f2;
}

.elm-datepicker--dow {
  cursor: default;
  border-bottom: 1px solid #ccc;
}

.elm-datepicker--day {
  cursor: pointer;
}

.elm-datepicker--day:hover {
  background: #f2f2f2;
}

.elm-datepicker--disabled {
  cursor: default;
  color: #ddd;
}

.elm-datepicker--disabled:hover {
  background: inherit;
}

.elm-datepicker--picked {
  color: #fff;
  background: #00008b;
}

.elm-datepicker--picked:hover {
  background: #00008b;
}

.elm-datepicker--today {
  font-weight: bold;
}

.elm-datepicker--other-month {
  color: #aaa;
}

.elm-datepicker--other-month.elm-datepicker--disabled {
  color: #eee;
}

.elm-datepicker--other-month.elm-datepicker--picked {
  color: #fff;
}

/*# sourceMappingURL=index.abe3a9c9.css.map */
